<!-- 智慧服务/财务管理/薪酬管理-薪酬项目-类型管理 -->
<template>
    <div class="info">
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow">
				<global-page-back
                	detailPageTitle="薪酬类型管理"
                	@handlerGlobalType="goBack"
            	>
            	</global-page-back>

                <div class="expand-filter-wrapper content-main">
                    <div class="filter-wrap">
                        <expand-filter :formData="formData" marginBottom="0px" @clickBtn="clickBtn" @changeBtnFormType="changeBtnFormType"
                            :closeWidth="170">
                        </expand-filter>
                        <div class="button-line" v-has-permi="['salaryProjectType:add']"></div>
                        <div class="operation-btn-view">
                            <el-button v-has-permi="['salaryProjectType:add']" type="primary" @click="addType">
                                新增类型
                            </el-button>
                        </div>

                    </div>
                    <table-data :config="table_config" :tableData="table_data" v-loading="loadingTable"
                        backgroundHeader="#FAFBFC" :headerStyle="{ height: '50px' }" :rowStyle="{ height: '50px' }" ref="table"
                        id="table">
                        <template v-slot:state="slotData">
                            <el-switch v-model="slotData.data.state" :disabled="slotData.data.isDefault === '1'"
                                :active-value="1" :inactive-value="0" @change="switchHandler(slotData.data)"></el-switch>
                        </template>
                        <template v-slot:operation="slotData">
                            <el-button @click="handleEdit(slotData.data)"
                            v-hasPermi="['salaryProjectType:edit']" style="color: #177EE6; border: none;background: none;">
                                编辑
                            </el-button>
                            <el-button @click="handleDelete(slotData.data)" v-if="slotData.data.isDefault === '0'"
                                v-hasPermi="['salaryProjectType:delete']" style="color: #E63A2E; border: none;background: none;">
                                删除
                            </el-button>
                        </template>
                    </table-data>
                    <pagination :total="total" :page.sync="listQuery.pageNum" :limit.sync="listQuery.pageRow"
                        @pagination="getList(true)" />
                    <!-- 新增类型 -->
                    <dialog-wrapper :dialogObj="dialogObj" @handleClose="handleClose">
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="right" label-width="94px" class="common-form">
                            <el-form-item label="类型名称" prop="name">
                                <el-input v-model="ruleForm.name" v-if="isDefault" placeholder="请填写类型名称" show-word-limit class="input-width-medium"
                                    maxlength="20"></el-input>
                                <el-input v-model="ruleForm.name" v-else disabled  placeholder="请填写类型名称" show-word-limit class="input-width-medium"
                                    maxlength="20"></el-input>
                            </el-form-item>
                            <el-form-item label="排序" prop="sort">
                                <el-input v-model="ruleForm.sort" :min="0" oninput="value = value.replace(/[^\d]/g,'')"
                                    placeholder="请输入排序值" type="number" class="input-width-medium"></el-input>
                            </el-form-item>
                            <el-form-item label="描述信息" prop="remark">
                                <el-input type="textarea" v-model="ruleForm.remark" :rows="3" show-word-limit maxlength="200" class="input-width-medium"
                                    placeholder="请填写薪酬类型描述信息"></el-input>
                            </el-form-item>
                            <div style="text-align: right">
                                <el-button @click="handleClose" class="dialog-reset-button">取消</el-button>
                                <el-button @click="submitForm()" type="primary">确定</el-button>
                            </div>
                        </el-form>
                    </dialog-wrapper>
                </div>
            </div>
        </div>
        <error v-else :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    FilterData,
    Loading,
    Pagination,
    DialogWrapper
} from "common-local";
import TableData from "../Sub/TableData";
import ExpandFilter from "../Sub/ExpandFilter.vue";
import selectTree from "../Sub/selectTree";
import { SalaryProjectModel } from "@/models/SalaryProject.js"
import GlobalPageBack from "../Sub/GlobalPageBack/index.vue";

// 逻辑运算
import system from '@/operations/system.js';
import onResize from "@/mixins/onResize"
export default {
    mixins: [onResize],
    name: "SalaryProjectDetailType",
    components: {
        selectTree,
        FilterData,
        Loading,
        TableData,
        Pagination,
        ExpandFilter,
        DialogWrapper,
		GlobalPageBack
    },
    props: {
        routerReturn: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            loadingShow: false,
            errorShow: false,
            loadingTable: false,
            total: 0,
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                name: '',
                schoolId: '',
                dictKey: 'salary',
            },
            // 头部筛选
            formData: {
                btnFormType: true,
                formInline: {
                    teacherName: "",
                },
                data: [
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "请输入类型名称",
                        key: "name",
                    }],
                btnList: [{
                    type: "primary",
                    text: "查询",
                    fn: "primary",
                    auth: ["salaryProjectType:list"],
                },]
            },
            table_config: {
                thead: [{
                    label: "薪酬类型",
                    prop: "name",
                    align: 'center'
                }, {
                    label: "排序",
                    prop: "sort",
                    align: 'center',
                    className: 'text-spacing-reduction'
                }, {
                    label: "状态",
                    type: "slot",
                    slotName: "state",
                    fixed: false,
                    align: 'center',
                    className: 'text-spacing-reduction'
                }, {
                    label: "添加人",
                    prop: "createName",
                    align: 'center'
                }, {
                    label: "添加时间",
                    prop: "createTime",
                    align: 'center'
                }, {
                    label: "操作",
                    type: "slot",
                    slotName: "operation",
                    className: 'text-spacing-reduction'
                }],
                check: false,
                height: "",
                leftEmpty:true
            },
            table_data: [],
            dialogObj: {
                title: '新增类型',
                dialogVisible: false,
                width: '640px'
            },
            ruleForm: {
                dictKey: '',
                name: '',
                sort: '',
                remark: '',
                schoolId: this.$store.state.schoolId,
                id: '',
            },
            rules: {
                name: [{
                    required: true,
                    message: '请输入门类名称',
                    trigger: ['change', 'blur']
                }],
                sort: [{
                    required: true,
                    message: '请输入排序值',
                    trigger: ['change', 'blur']
                }],
            },
            isDefault: '',//编辑-应发、实发工资类型名称不可编辑
        }
    },
    created() {
        this.getList()
		this.$eventDispatch('setGlobalPageType', 'form')
    },
    mounted() {
    },
    methods: {
        /**
         * @Description: 返回
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 15:20:47
         */
        goBack() {
            this.$emit("backList");
        },
        /**
         * @Description: 获取列表数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 15:23:24
         */
        getList(isPaging) {
            if (!isPaging) {
                this.listQuery.pageNum = 1
            }
            this.listQuery.schoolId = this.$store.state.schoolId
            const salaryProjectModel = new SalaryProjectModel();
            salaryProjectModel.getSalaryProjectList(this.listQuery).then(res => {
                // this._fet("/school/schoolDictValue/salaryList", this.listQuery).then(res => {
                if (res.data.code === "200") {
                    this.table_data = res.data.data.list
                    this.total = res.data.data.totalCount
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description: 查询、新增类型
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 15:19:28
         */
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    this.getList(1);
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 新增类型
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 15:19:08
         */
        addType() {
            this.isDefault = true
            this.dialogObj.dialogVisible = true
        },
        /**
         * @Description: 关闭、取消新增类型弹框
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 15:25:34
         */
        handleClose() {
            this.$refs.ruleForm.resetFields()
            Object.keys(this.ruleForm).forEach((key) => {
                this.ruleForm[key] = ''
            })
            this.dialogObj.dialogVisible = false
            this.ruleForm.schoolId = this.$store.state.schoolId
        },
        /**
         * @Description: 新增类型-提交
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 15:26:08
         */
        submitForm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.ruleForm.dictKey ='salary'
                    this._fet('/school/schoolDictValue/save', this.ruleForm).then((res) => {
                        if (res.data.code === '200') {
                            this.$message.success('保存成功')
                            this.handleClose();
                            this.getList();
                        }else if(res.data.code === '20087'){
                            this.$message.error('薪酬类型已存在，请核对信息！')
                        }
                        else {
                            this.$message.error(res.data.msg)
                        }
                    })
                }
            })
        },
        /**
         * @Description: 切换状态
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 15:35:40
         */
        switchHandler(data) {
            const salaryProjectModel = new SalaryProjectModel()
            salaryProjectModel
                .setSwitchingStatus({ id: data.id, state: data.state, schoolId: this.$store.state.schoolId })
                .then((res) => {
                    if (res.data.code == "200") {
                        this.$message.success("修改成功");
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });

        },
        /**
         * @Description: 操作-编辑
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 15:26:42
         */
        handleEdit(data) {
            if(data.isDefault === '1'){
                this.isDefault = false
            }else{
                this.isDefault = true
            }
            this.$nextTick(() => {
                Object.keys(this.ruleForm).forEach((key) => {
                    this.ruleForm[key] = data[key]
                })
            })
            this.dialogObj.dialogVisible = true
        },
        /**
         * @Description: 操作-删除
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 15:27:11
         */
        handleDelete(data) {
            this.$confirm('是否确定删除, 删除后不可恢复?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const salaryProjectModel = new SalaryProjectModel()
                salaryProjectModel.getSalaryProjectDelete({ id: data.id,schoolId: this.$store.state.schoolId }).then((res) => {
                    if (res.data.code === '200') {
                        this.$message.success('删除成功')
                        this.getList()
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            }).catch(() => {
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.filter-wrap {
    display: flex;
    // justify-content: space-between;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 20px 0;
    overflow: hidden;
    margin-bottom: 10px;
    border-radius: 4px;

    .filter-right-button {
        border-color: transparent;
    }

    .expand-filter {
        padding: 0;

        .date-picker-wrap {
            display: flex;
            align-items: center;

            .date-icon {
                margin-left: 10px;
                user-select: none;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 14px;
                height: 14px;
                background: #d4d6d9;
                border-radius: 50%;
                color: #ffffff;
                font-size: 12px;
                cursor: pointer;
            }
        }
    }

    .operation-btn-view {
        flex-shrink: 0;
        padding-right: 0;

        ::v-deep .filter-right-button:hover {
            filter: brightness(1.1);
        }
    }
}

.body-title {
    display: flex;
    background-color: #ffffff;
    align-items: center;
    padding: 10px;
    border: 1px solid #EDF0F2;
    // margin-top: 8px;
    box-sizing: border-box;
    border-radius: 4px;

    .line {
        margin-left: 5px;
        width: 2px;
        height: 15px;
        background-color: #dde6f2;
    }

    .title {
        margin-left: 19px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2B2F33;
    }

    .return-btn {
        display: flex;
        color: #2B2F33;
        cursor: pointer;
        font-size: 14px;
        align-items: center;

        &:hover {
            color: #3C7FFF;
        }

        i {
            color: #363A40;
            margin-right: 19px;

            &:hover {
                color: #3C7FFF;
            }
        }
    }
}
</style>
