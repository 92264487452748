<template>
    <div class="upload-history-view">
        <div class="upload-history">
            <div
                v-for="(item, index) in list"
                :key="index"
                class="upload-history-item"
            >
                <div class="info">
                    <div class="name">
                        {{ item.createName }}
                        <img
                            class="avatar"
                            v-if="item.createImg"
                            :src="item.createImg"
                            alt="头像"
                        />
                    </div>
                    <div class="time">
                        {{ item.createTime }}
                    </div>
                    <div class="icon">
                        <i
                            v-if="item.hasFail == '1'"
                            class="warning el-icon-warning"
                        ></i>
                        <i
                            v-if="item.hasFail == '0'"
                            class="success el-icon-success"
                        ></i>
                    </div>
                </div>
                <div class="file-info">导入项目: {{ item.projectName }}</div>
                <div class="details">
                    <div class="details-wrapper">
                        <div class="file-name">
                            <img
                                :src="excel"
                                alt="文件"
                            />
                        </div>
                        <div class="file-info">
                            <div class="upload-name">
                                {{ item.uploadName }}
                            </div>
                            <div class="file-type">
                                <span class="success-count"
                                    >{{ item.successCount }}条信息导入成功<span
                                        v-if="item.failCount"
                                        >，</span
                                    > </span
                                ><span
                                    class="fail-count"
                                    v-if="item.failCount"
                                    >{{ item.failCount }}条信息导入失败。</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "UploadHistory",
    props: {
        list: Array,
    },
    computed: {
        excel() {
            return require("@/assets/images/excel.png");
        },
    },
};
</script>
<style lang="scss" scoped>
.upload-history-view {
    max-height: 600px;
    overflow: auto;
    .upload-history {
        width: 500px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 0 20px;
        .upload-history-item {
            margin-bottom: 40px;
            .info {
                display: flex;
                align-items: center;
                .name {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #363b40;
                    .avatar {
                        width: 20px;
                        height: 20px;
                        margin-left: 5px;
                        border-radius: 50%;
                    }
                }
                .time {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #363b40;
                    margin-right: auto;
                    margin-left: 10px;
                }
                .icon {
                    .warning {
                        color: #fdbc4d;
                    }
                    .success {
                        color: #46cc95;
                    }
                }
            }
            .file-info {
                margin-top: 5px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #bdbcbd;
            }
            .details {
                margin-top: 5px;
                border: 1px solid #f3f3f3;
                border-radius: 4px;
                .details-wrapper {
                    display: flex;
                    box-sizing: border-box;
                    padding: 10px 20px;
                    .file-name {
                        width: 40px;
                        height: 42px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .file-info {
                        margin-left: 10px;
                        .upload-name {
                            font-size: 14px;
                            color: #bdbcbd;
                        }
                        .file-type {
                            margin-top: 2px;
                            .success-count {
                                color: #bdbcbd;
                            }
                            .fail-count {
                                color: #fdcb7a;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
