<!-- 薪酬详情 - 表格设置 -->
<template>
    <div>
        <div
            v-if="!errorShow"
            class="right-panel"
        >
            <loading :loadingShow="loadingShow"></loading>
            <div
                v-if="!loadingShow"
                class="content-wrapper"
            >
                <el-drawer
                    title="表格显示设置"
                    :visible.sync="isShow"
                    direction="rtl"
                    ref="drawerExam"
                    size="320px"
                    class="details"
                    :append-to-body="true"
                    :modal-append-to-body="false"
                    :with-header="false"
                >
                    <div
                        style="
                            background-color: white;
                            margin-top: 40px;
                            padding: 20px;
                        "
                    >
                        <h4>表格显示设置</h4>
                        <div class="draw_disFlex">
                            <draggable
                                class="drawing-board"
                                style="margin-left: 10px"
                                :list="menuList"
                                :animation="340"
                                group="componentsGroup"
                            >
                                <template v-for="(item, index) in menuList">
                                    <div class="menu_item">
                                        <div class="item_disFlex">
                                            <img
                                                :src="setIcon"
                                                style="
                                                    width: 6px;
                                                    height: 10px;
                                                "
                                            />
                                            <div class="text">
                                                {{ item.label }}
                                            </div>
                                        </div>

                                        <el-switch
                                            v-model="item.state"
                                        ></el-switch>
                                    </div>
                                </template>
                            </draggable>
                        </div>
                    </div>
                </el-drawer>
            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    // 业务组件

    TableData,

    // 功能组件
    Error,
    Loading,
} from "common-local";
import draggable from "vuedraggable";

export default {
    name: "TableItemConfiguration",
    props: {
        isShowDrawer: {
            type: Boolean,
            default: true,
        },
        menuList: {
            type: Array,
        },
        iconList: {
            type: Array,
        },
    },

    components: {
        TableData,
        // 功能组件
        Error,
        Loading,
        draggable,
    },
    computed: {
        isShow: {
            set(val) {
                this.$emit("closeDrawer", val);
            },
            get() {
                return this.isShowDrawer;
            },
        },
        setIcon() {
            return require("@/assets/images/sortIcon.png");
        },
    },
    data() {
        return {
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: true,
        };
    },
    created() {},
    mounted() {},
    methods: {},
};
</script>

<style scoped lang="scss">
.details {
    width: 100%;
    z-index: 1;
}

.draw_disFlex {
    display: flex;
    margin-top: 40px;
    width: 100%;
    cursor: pointer;
}

.drawing-board {
    width: 100%;
}

.el-drawer__container {
    width: 100%;
    float: right;
}

.menu_item {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item_disFlex {
    display: flex;
    align-items: center;

    .text {
        margin-left: 10px;
    }
}
</style>
